import {
  isServer,
  QueryClient,
  QueryClientConfig,
  type UseMutationOptions,
  type UseQueryOptions,
} from '@tanstack/react-query';
import { merge } from 'lodash';

import type { ContactPersonUpdateDataType } from '@/types/api/contact-persons.type';
import type { UserCreateDataType } from '@/types/api/users.type';
import { API_Actions } from '@/libs/api.actions';

/**
 * Query Client
 */
let browserQueryClient: QueryClient | undefined = undefined;
function makeQueryClient(config?: QueryClientConfig) {
  return new QueryClient(
    merge(
      {
        defaultOptions: {
          queries: {
            staleTime: 60 * 1000,
            refetchInterval: 30 * 1000,
            refetchOnWindowFocus: true,
            refetchIntervalInBackground: true,
          },
          mutations: {
            //TODO: make it global wihtout crashing the ssr, i mean use toast or logs dynamically depends on where we call it
            // onError: (error) => {
            //   console.error('API error:', error);
            // },
          },
          // dehydrate: {
          //   shouldDehydrateQuery: (query) =>
          //     defaultShouldDehydrateQuery(query) || query.state.status === 'pending',
          // },
        },
      } satisfies QueryClientConfig,
      config
    )
  );
}
export function getQueryClient(config?: QueryClientConfig) {
  if (isServer) return makeQueryClient(config);
  else {
    // Browser: make a new query client if we don't already have one
    // This is very important, so we don't re-make a new client if React
    // suspends during the initial render. This may not be needed if we
    // have a suspense boundary BELOW the creation of the query client
    if (!browserQueryClient) browserQueryClient = makeQueryClient(config);
    return browserQueryClient;
  }
}
export const queryClient = getQueryClient();

// export const queryClient = new QueryClient({
//   defaultOptions: {
//     queries: {
//       staleTime: 10 * 1000,
//       refetchInterval: 30 * 1000,
//     },
//     mutations: {
//       //TODO: make it global wihtout crashing the ssr, i mean use toast or logs dynamically depends on where we call it
//       // onError: (error) => {
//       //   console.error('API error:', error);
//       // },
//     },
//   },
// });

/**
 * Types: Query
 */
type QueryParamsType = {
  //users
  'users.list': never;
  'users.get': number;
  'users.profile': never;
  //admins
  'admins.list': never;
  //contacts
  'contacts.list': never;
  'contacts.get': number;
  //emails
  'emails.list': never;
  'emails.get': number;
  //categories
  'categories.list': never;
  'categories.get': number;
  //products
  'products.list': never;
  'products.get': number;
  //delivery
  'delivery.get': never;
  'delivery.settings': never;
};
export type API_QueryPaths = keyof QueryParamsType;

export type GetQueryType<P extends API_QueryPaths> = Awaited<
  ReturnType<(typeof API_QueryMap)[P]['queryFn']>
>;
export type DynamicParamsType<P extends API_QueryPaths> =
  QueryParamsType[P] extends undefined | null | never //prettier-ignore
    ? { params?: never }
    : { params: QueryParamsType[P] };

/**
 * Types: Mutation
 */
export type API_MutationPaths =
  | 'users.create'
  | 'users.delete'
  | 'users.update'
  | 'users.login-as'
  | 'users.logout'
  | 'users.sync'
  | 'users.sync.id'
  | 'users.restore'
  | 'users.reset-password'
  | 'admins.sync'
  | 'contacts.delete'
  | 'contacts.import'
  | 'contacts.export'
  | 'contacts.update'
  | 'contacts.create'
  | 'contacts.restore'
  | 'categories.create'
  | 'categories.update'
  | 'categories.delete'
  | 'categories.status'
  | 'categories.move'
  | 'products.sync'
  | 'products.sync.id'
  | 'products.saleable'
  | 'delivery.update'
  | 'delivery.sync'
  | 'emails.update';

export type GetMutationType<
  P extends API_MutationPaths,
  Prop extends 'ReturnType' | 'Variables' = 'ReturnType',
> = Prop extends 'ReturnType'
  ? Awaited<ReturnType<(typeof API_MutationMap)[P]['mutationFn']>>
  : Parameters<(typeof API_MutationMap)[P]['mutationFn']>[number] extends never
    ? void
    : Parameters<(typeof API_MutationMap)[P]['mutationFn']>[number];

/**
 * Constants: Query
 */
export const API_QueryMap = {
  // users
  'users.list': {
    queryKey: ['users', 'list'],
    queryFn: API_Actions.users.list,
  },
  'users.get': {
    queryKey: ['users', 'get'],
    queryFn: ({ meta }) => API_Actions.users.get(meta?.params ?? 0),
  },
  'users.profile': {
    queryKey: ['users', 'profile'],
    queryFn: API_Actions.users.profile,
  },
  // admins
  'admins.list': {
    queryKey: ['admin-users', 'list'],
    queryFn: API_Actions.admins.list,
  },
  // contacts
  'contacts.list': {
    queryKey: ['contact-persons', 'list'],
    queryFn: API_Actions.contacts.list,
  },
  'contacts.get': {
    queryKey: ['contact-persons', 'get'],
    queryFn: ({ meta }) => API_Actions.contacts.get(meta?.params ?? 0),
  },
  // emails
  'emails.list': {
    queryKey: ['email-templates', 'list'],
    queryFn: API_Actions.emails.list,
  },
  'emails.get': {
    queryKey: ['email-templates', 'get'],
    queryFn: ({ meta }) => API_Actions.emails.get(meta?.params ?? 0),
  },
  // categories
  'categories.list': {
    queryKey: ['product-categories', 'list'],
    queryFn: API_Actions.categories.list,
  },
  'categories.get': {
    queryKey: ['product-categories', 'get'],
    queryFn: ({ meta }) => API_Actions.categories.get(meta?.params ?? 0),
  },
  // products
  'products.list': {
    queryKey: ['products', 'list'],
    queryFn: API_Actions.products.list,
  },
  'products.get': {
    queryKey: ['products', 'get'],
    queryFn: ({ meta }) => API_Actions.products.get(meta?.params ?? 0),
  },
  // delivery
  'delivery.get': {
    queryKey: ['delivery', 'get'],
    queryFn: API_Actions.delivery.get,
  },
  'delivery.settings': {
    queryKey: ['delivery', 'settings'],
    queryFn: API_Actions.delivery.settings,
  },
} satisfies Record<API_QueryPaths, UseQueryOptions>;

/**
 * Constants: Mutation
 */
export const API_MutationMap = {
  // users
  'users.create': {
    mutationKey: ['users', 'create'],
    mutationFn: API_Actions.users.create,
  },
  'users.delete': {
    mutationKey: ['users', 'delete'],
    mutationFn: API_Actions.users.delete,
  },
  'users.update': {
    mutationKey: ['users', 'update'],
    mutationFn: (params: { id: number; data: UserCreateDataType }) =>
      API_Actions.users.update(params.id, params.data),
  },
  'users.login-as': {
    mutationKey: ['users', 'login-as'],
    mutationFn: API_Actions.users.loginAs,
  },
  'users.logout': {
    mutationKey: ['users', 'logout'],
    mutationFn: API_Actions.users.logout,
  },
  'users.sync': {
    mutationKey: ['users', 'sync'],
    mutationFn: API_Actions.users.sync,
  },
  'users.sync.id': {
    mutationKey: ['users', 'sync', 'id'],
    mutationFn: API_Actions.users.syncId,
  },
  'users.restore': {
    mutationKey: ['users', 'restore'],
    mutationFn: API_Actions.users.restore,
  },
  'users.reset-password': {
    mutationKey: ['users', 'reset-password'],
    mutationFn: API_Actions.users.resetPassword,
  },
  // admins
  'admins.sync': {
    mutationKey: ['admins', 'sync'],
    mutationFn: API_Actions.admins.sync,
  },
  // contacts
  'contacts.delete': {
    mutationKey: ['contacts', 'delete'],
    mutationFn: API_Actions.contacts.delete,
  },
  'contacts.import': {
    mutationKey: ['contacts', 'import'],
    mutationFn: API_Actions.contacts.import,
  },
  'contacts.export': {
    mutationKey: ['contacts', 'export'],
    mutationFn: API_Actions.contacts.export,
  },
  'contacts.update': {
    mutationKey: ['contacts', 'update'],
    mutationFn: (params: { id: number; data: ContactPersonUpdateDataType }) =>
      API_Actions.contacts.update(params.id, params.data),
  },
  'contacts.create': {
    mutationKey: ['contacts', 'create'],
    mutationFn: API_Actions.contacts.create,
  },
  'contacts.restore': {
    mutationKey: ['contacts', 'restore'],
    mutationFn: API_Actions.contacts.restore,
  },
  // categories
  'categories.create': {
    mutationKey: ['categories', 'create'],
    mutationFn: API_Actions.categories.create,
  },
  'categories.update': {
    mutationKey: ['categories', 'update'],
    mutationFn: API_Actions.categories.update,
  },
  'categories.delete': {
    mutationKey: ['categories', 'delete'],
    mutationFn: API_Actions.categories.delete,
  },
  'categories.status': {
    mutationKey: ['categories', 'status'],
    mutationFn: API_Actions.categories.status,
  },
  'categories.move': {
    mutationKey: ['categories', 'move'],
    mutationFn: API_Actions.categories.move,
  },
  // products
  'products.sync': {
    mutationKey: ['products', 'sync'],
    mutationFn: API_Actions.products.sync,
  },
  'products.sync.id': {
    mutationKey: ['products', 'sync', 'id'],
    mutationFn: API_Actions.products.syncId,
  },
  'products.saleable': {
    mutationKey: ['products', 'saleable'],
    mutationFn: API_Actions.products.saleable,
  },
  // delivery
  'delivery.update': {
    mutationKey: ['delivery', 'update'],
    mutationFn: API_Actions.delivery.update,
  },
  'delivery.sync': {
    mutationKey: ['delivery', 'sync'],
    mutationFn: API_Actions.delivery.sync,
  },
  'emails.update': {
    mutationKey: ['emails', 'update'],
    mutationFn: API_Actions.emails.update,
  },
} satisfies Record<API_MutationPaths, UseMutationOptions<any, Error, any>>;

/** Utility functions */
export const generateQueryKey = <P extends API_QueryPaths>(
  path: P,
  params?: QueryParamsType[P]
) => ({
  ...API_QueryMap[path],
  meta: { params },
  queryKey: [...API_QueryMap[path].queryKey, params],
});
