import axios, { AxiosError } from 'axios';

import { API_ErrorType } from '@/types/api/api-error.type';
import { UserLoginResponse } from '@/types/api/users.type';
import { AuthTokenName } from '@/configs/app';
import { deleteCookie, getCookie } from '@/libs/cookie';

/**
 * Constants
 */
const unknownError = 'UNKNOWN ERROR';
const isServer = () => typeof window === 'undefined';

export const axios_ = axios.create({
  baseURL:
    typeof window === 'undefined' ? `http://localhost:${process.env.PORT ?? 3000}` : undefined,
  withCredentials: true,
  // headers: {
  //   'Content-Type': 'application/json',
  // },
});

axios_.interceptors.request.use(
  async (config) => {
    const token = getCookie<UserLoginResponse['token']>(AuthTokenName);
    if (token) config.headers['Authorization'] = `Bearer ${token}`;
    return config;
  },
  Promise.reject,
  { runWhen: (config) => !config.headers.has('Authorization') }
);

axios_.interceptors.response.use(
  (res) => res,
  (error: AxiosError<API_ErrorType>) => {
    const errorData = error.response?.data;
    // console.error('axios_.interceptors.response.use', errorData, error);

    if (errorData?.error) {
      /**
       * Note:
       *? Redirects to login page, if the token is invalid or expired
       * OR
       *? Delete existing cookie on client side, if the database has no record for that token,
       *? usually it can happen when the server is restarted or redeployed
       */
      if (
        !isServer() &&
        !location.pathname.startsWith('/auth') &&
        (errorData.error.status_code === 401 || //
          errorData.error.message === 'Failed to get auth')
      ) {
        deleteCookie(AuthTokenName);
        location.assign(
          new URL(
            '/auth/login?' + new URLSearchParams({ redirect: location.pathname }).toString(),
            location.origin
          ).toString()
        );
      }

      errorData.error.message_ = `(${errorData.error.status_code}) ${errorData.error.status}: ${errorData.error?.message ?? unknownError}`;
      return Promise.reject(errorData.error);
    }
    return Promise.reject(
      (errorData as unknown as Error)?.message ?? error?.message ?? unknownError
    );
  }
);

export default axios_;
