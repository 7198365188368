import {
  deleteCookie as deleteCookie_,
  getCookie as getCookie_,
  type CookieValueTypes,
} from 'cookies-next';
import type { OptionsType } from 'cookies-next/lib/types';

/**
 * Get cookie function
 * @description This is multi-platform function that helps us simplify the code, and can be used on server side and client side as well.
 */
export const getCookie = <T = CookieValueTypes>(key: string, options?: OptionsType): T => {
  const cookie =
    typeof window === 'undefined'
      ? getCookie_(key, { ...options, cookies: require('next/headers').cookies })
      : getCookie_(key, options);
  return cookie as T;
  // return cookie ? JSON.parse(cookie) : cookie;
};

export const deleteCookie = (key: string, options?: OptionsType) => {
  typeof window === 'undefined'
    ? deleteCookie_(key, { ...options, cookies: require('next/headers').cookies })
    : deleteCookie_(key, options);
};
