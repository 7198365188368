import(/* webpackMode: "eager", webpackExports: ["ConfigProvider"] */ "__barrel_optimize__?names=ConfigProvider!=!/app/node_modules/antd/es/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/@ant-design/nextjs-registry/es/AntdRegistry.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next-runtime-env/build/provider/env-provider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next-runtime-env/build/provider/use-env-context.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/src/app/globals.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/libs/fonts.ts\",\"import\":\"Roboto\",\"arguments\":[{\"preload\":false,\"display\":\"swap\",\"subsets\":[\"latin\"],\"weight\":[\"300\",\"400\",\"500\",\"700\"],\"adjustFontFallback\":false,\"variable\":\"--font-roboto-sans\",\"fallback\":[\"system-ui\",\"Arial\",\"sans-serif\"]}],\"variableName\":\"fontRoboto\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/libs/fonts.ts\",\"import\":\"Inter\",\"arguments\":[{\"preload\":false,\"display\":\"swap\",\"subsets\":[\"latin\"],\"adjustFontFallback\":false,\"variable\":\"--font-inter-sans\",\"fallback\":[\"system-ui\",\"Arial\",\"sans-serif\"]}],\"variableName\":\"fontInter\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/providers/ReactQuery.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/providers/StyledComponentsRegistry.tsx");
